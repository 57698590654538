<template>
  <div class="demandItem" @click="goSettleDetail">
    <div class="demandInfo">
      <img :src="item.itsFiles.length ? item.itsFiles[0].fileUrl : supplierImg"/>
      <div class="venueInfo">
        <div class="venueTitleBox">
          <p class="venueTitle">{{item.supplierName}}</p>
          <span class="tagSign" :style="{color: getColor(item)}"> {{item.statusTxt}}</span>
        </div>
        <p class="venueType">
          <span v-if="orderList.isSelectedVenue">酒店会场</span>
          <span v-if="orderList.isSelectedMeal">酒店用餐</span>
          <span v-if="orderList.isSelectedRoom">酒店住宿</span>
          <span v-if="orderList.isSelectedOther">酒店其他</span>
        </p>
      </div>
    </div>
    <div class="peopleInfo" v-if="!item.isOffline">
      <div class="supplierContact" >
        <span>联系人</span>
        <span class="peopleColor">{{item.contactName}}</span>
      </div>
      <div class="supplierContact">
        <span><svg class="icon" aria-hidden="true">
          <use xlink:href="#icondianhua"></use>
        </svg></span>
        <span class="peopleColor">{{item.contactMobile}}</span>
      </div>
    </div>
    <div class="priceInfo">
      <div class="supplierPrice">
        <span>报价</span>
        <span class="priceColor">{{(item.quoteAmount || 0) | currencyFormatter}}</span>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'demandItem',
  props: {
    item: {
      type:Object,
      default(){
        return {
          itsFiles: []
        }
      }
    },
    orderList: {
      type:Object,
      default(){
        return {}
      }
    },
    status: {
      type: Number,
      default: 0
    },
    taskId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      supplierImg: require('../../../assets/img/detailsImg.png'),
    }
  },
  methods: {
    goSettleDetail () {
      this.$SmartStorage.set('supplierId', this.item.supplierId)
      this.$SmartStorage.set('taskStatus', this.status)
      this.$SmartStorage.set('taskId', this.taskId)
      this.$router.push('/quotedPriceDemandList')
    },
    getColor (item) {
      let color = '#a67c0b'
      if ([-1].includes(item.status)) {
        color = '#909399'
      } else if ([11,30,31,37,51,100].includes(item.status)) {
        color = '#4ac672'
      } else if ([12,20].includes(item.status)) {
        color = '#ff484d'
      }
      return color
    }
  }
}
</script>
<style scoped lang="scss">
.demandItem {
  padding: .1rem;
  background: linear-gradient(-55deg, #FFFFFF, #F8F8F8);
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
  border-radius: .04rem;
  position: relative;
  margin-bottom: .1rem;
  .demandInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    img {
      width: .37rem;
      height: .37rem;
      border-radius: .04rem;
    }
    .venueInfo {
      margin-left: .1rem;
      text-align: left;
      width: 100%;
      .venueTitleBox {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .venueTitle {
        font-size: .15rem;
        font-weight: bold;
        color: #13161A;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 2.2rem;
      }
      .venueType{
        span{
          color: var(--themeColor);
          font-size: .12rem;
          margin-right: 0.1rem;
          display: inline-block;
          padding: 0.02rem 0.05rem;
          position: relative;
          border-radius: .04rem;
          overflow: hidden;
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: .1;
            left: 0;
            bottom: 0;
            background-color: var(--themeColor);
          }
        }
      }
    }
  }
  .peopleInfo {
    text-align: left;
    margin-top: .1rem;
    .supplierContact {
      display: inline-block;
      margin-right: .1rem;
      span{
        font-size: .13rem;
        font-weight: 500;
        color: #90939A;
      }
      .peopleColor{
        font-size: .15rem;
        font-weight: 500;
        color: #13161A;
      }
      .icon{
        width: 20px;
        height: 15px;
        color: #3875FE;
      }
    }
  }
  .priceInfo {
    text-align: left;
    .supplierPrice {
      display: inline-block;
      margin-right: .1rem;
      span{
        font-size: .13rem;
        font-weight: 500;
        color: #90939A;
      }
      .priceColor{
        font-size: .15rem;
        font-weight: 500;
        color: #13161A;
      }
    }
  }
  .tagSign {
    color: #FECE30;
    font-size: .13rem;
    font-weight: 100;
  }
  .costListBtnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: .1rem;
    border-top: 1px solid #fff;
    padding-top: .1rem;
    .costListBtn {
      padding: .02rem .1rem;
      background: #fff;
      margin: 0 .1rem;
      border-radius: .04rem;
      border-top: 1px solid #fff;
    }
  }
}
</style>
