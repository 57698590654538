<template>
    <section>
      <sm_header title='酒店报价' :smHleftEvent='closeWebview'>
        <template v-slot:smHright v-if="![0,10].includes(orderList.status)">
          <p @click.stop="goDemandList(orderList,'settle')">需求列表</p>
        </template>
      </sm_header>
        <div class="loadmore_container" ref="wrapper" :style="{ height: wrapperHeight + 'px' }">
        <i-scrollBox class="opOrderListScroll" @on-top="scrollTop" :loadType="loadType" @on-bottom="scrollBottom">
            <template v-slot:scrollBox>
                <demand-item v-for="(item, i) in itsSupplierExpenses" :key="i" :taskId="orderList.taskId"
                             :item="item" :status="orderList.taskStatus" :orderList="orderList"></demand-item>
                <NoData v-if="!orderList&&loadType==''&&!isFirst"/>
            </template>
        </i-scrollBox>
        <div class="priceInfo">
          <p class="priceTitle">报价/结算信息</p>
          <p class="priceTxt">
            <span>酒店总报价</span>
            <span class="priceColor">{{(orderList.itsProposalTotalAmount.quoteAmount || 0) | currencyFormatter}}</span>
          </p>
        </div>
        </div>
      <div class="footerBtnListBox">
        <div v-for="(item, index) in btnList" :key="index" class="largeBtn4" @click="actionClick(item)">{{ item.actName }}</div>
        <div class="largeBtn4" @click.stop="goProjectProcess()">查看进程</div>
      </div>
    </section>
</template>
<script>
    import iScrollBox from "@/components/iScrollBox/iScrollBox";
    import generalBridge from "@/plugin/generalBridge.js";
    import demandItem from "@/view/supplierList/components/demandItem";
    export default {
        name: 'supplierList',
        components: {
            iScrollBox,
          demandItem
        },
        data () {
            return {
                orderList: {
                    itsProposalTotalAmount: {
                        quoteAmount: 0
                    }
                },
                itsSupplierExpenses: [],
                fromPage: false,
                isFirst: true,
                loadType: '',
                proposalId: '',
                supplierImg: require('../../assets/img/detailsImg.png'),
                eventData: {},
                btnList: [],
                wrapperHeight: 0,
            }
        },
        mounted() {
            this.wrapperHeight = (document.documentElement.clientHeight || window.innerHeight) - this.$refs.wrapper.getBoundingClientRect().top;
        },
        computed: {
        },
        created() {
            this.eventData = this.$SmartStorage.get("eventData");
            if (this.eventData) {
                this.proposalId = this.eventData.proposalId;
            } else {
                this.proposalId = this.$SmartStorage.get("proposalId");
            }
            this.$SmartStorage.remove('taskId')
            this.$SmartStorage.remove('taskStatus')
            this.$SmartStorage.remove('supplierId')
            this.paingEOrder()
            this.andriodBack()
        },
        methods: {
            async paingEOrder (clear) {
                let params = {
                    ProposalId: this.proposalId,
                    "NodeCode": 'ConfirmQuote',
                    "supplierType": 'V2'
                }
                let res = await this.$service.GetConfirmTask(params)
                this.isFirst = false
                if (res && res.content) {
                    if (clear) {
                        this.orderList = {
                            itsProposalTotalAmount: {
                                quoteAmount: 0
                            }
                        };
                        this.itsSupplierExpenses = []
                    }
                    if (res.content.taskStatus != 1) {
                        this.QueryTaskActions(res.content.taskId);
                    }
                    this.orderList = res.content
                    this.itsSupplierExpenses = res.content.itsSupplierExpenses || []
                    this.loadType = '';
                }
            },
            scrollTop() {
                this.loadType = 'top';
                this.paingEOrder(true)
            },
            scrollBottom() {
                this.loadType = '';
            },
            // 安卓返回键
            andriodBack() {
                this.setRouteListeners('requireListGoBack'); // 保存当前路由返回事件记录
                this.$root.$eventHub.$on("requireListGoBack", () => {
                    this.closeWebview();
                    this.$root.$eventHub.$off("requireListGoBack");
                });
            },
            // 返回按钮
            closeWebview() {
                generalBridge.propy.call(this)
            },
            goSettleDetail (item, list) {
                this.$SmartStorage.set('supplierId', list.supplierId)
                this.$SmartStorage.set('taskStatus', item.taskStatus)
                this.$SmartStorage.set('taskId', item.taskId)
                this.$router.push('/quotedPriceDemandList')
            },
            goDemandList () {
                // 去往需求列表
                this.$router.push({ path: "/", query: { fromPage: "supplierList" } });
            },
            async QueryTaskActions (taskId) {
                let params = {
                    taskId: taskId
                };
                let res = await this.$service.QueryTaskActions(params)
                if (res && res.success) {
                    res.content.forEach(ele => {
                        ele.color = ele.itsActData.color
                    })
                    this.btnList = res.content.reverse();
                }
            },
            actionClick (btn) {
                let msg = ''
                if (btn.actName == "重新添加需求") {
                    msg = "重新添加需求后所有需求将重新报价，确认是否继续";
                }
                if (btn.actName == "确认报价") {
                    msg = "所有的费用都已经报价，请确认酒店的取消条款，没有报价的未计入总价";
                }

                this.$dialog.confirm({
                    title: "提示",
                    message: msg
                }).then(async () => {
                    if (btn.actDirective !== 'task-done') {
                        return
                    }
                    let params = {
                        taskId: this.orderList.taskId,
                        nodeId: this.orderList.nodeId,
                        actionId: btn.actionId,
                        processId: this.orderList.proposalId
                    };
                    let res = await this.$service.ProcessTask(params)
                    if (res.success) {
                        this.$toast("已成功操作，即将退出ivenue");
                        this.closeWebview();
                    }
                })
            },
          goProjectProcess(){
            this.$router.push({ path: "/projectProcess", query: { fromPage: 'supplierList' }});
          }
        }
    }
</script>
<style lang="scss" scoped>
    @import "supplierList";
    .icon {
        width: .2rem;
        height: .2rem;
    }
</style>
<style lang="scss">
    .opOrderListScroll {
      padding:.44rem .1rem .15rem;
        .scrollSkeleton {
            padding: .15rem .25rem;
            width: auto;
        }
    }
</style>
